import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as CONSTANTS from "../../CONSTANTS";
import { FaBell } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import bellDing from "../../assets/bellDing.mp3"
import { useDispatch } from 'react-redux';
import { logout } from '../../reduxAuth/authSlice';

function FlagNotifications({user}) {

    const [flagListCount, setFlagListCount]                             = useState(0);    
    const [keepCounting, setKeepCounting]                               = useState(0);

    const dispatch                                                           = useDispatch();

    useEffect(() => {
        collectionOfFlags();
    },[keepCounting])

    const collectionOfFlags = async () => {
        try{
            const flagInfo = await axios.get(CONSTANTS.API_URL +"settings/notification/flags" ,{
                    headers: {
                        token: "Bearer " + user.accessToken
                    }
                  });

          //Play sound
          if(flagInfo.data.length > 0){
            if(flagInfo.data.length > flagListCount){
              new Audio(bellDing).play()
            }
          }

          setFlagListCount(flagInfo.data.length);
          console.log(flagInfo.data.length);
          /*
          setTimeout(function myStopFunction() {
              setKeepCounting((prevState) => prevState + 1);
          }, 9000);
          */

        }catch(err){
            console.log(err);
            //Check if token is invalid     
            if(err.response.status === 403){
                localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                dispatch(logout());
                toast.error("Session has timeout out.")
                setTimeout(() => {
                document.location.href("/login");
              }, 4000);
          }
        }
    }

  return (
    <div className="flag-item">
        <Link to="/flags" className="nav-notify">
          <div className="shade-back">
              <FaBell />
              <span className="red-count">{flagListCount}</span>
          </div>
        </Link>
    </div>
  )
}

export default FlagNotifications